import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import Navigation from './navbar'

const Header = (props) => {
    const [menu, setMenu] = useState(false);
    return (
        <header className="header">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-3 col-md-3 col-8">
                        <Link to="/" alt="Ir al inicio">
                            <img src="/images/logo.svg" alt="Logo Cajta" />
                        </Link>
                    </div>
                    <div className="col-4 d-lg-none d-md-none d-flex justify-content-end">
                        <button className="btn-menu" onClick={() => setMenu(!menu)}>
                            <i className="bi bi-list"></i>
                        </button>
                    </div>

                    <div className="col-lg-6 col-md-9 col-12">
                        <div className={`d-lg-block d-md-block d-block-sm ${menu ? 'd-flex flex-column' : 'd-none'}`}>
                            <Navigation active={props.active} />
                        </div>
                    </div>
                    <div className="col-lg-3 d-lg-flex d-none d-flex flex-row align-items-end justify-content-end">
                        <Link
                            to="/search"
                            alt="Buscar"
                            className={
                                props.active === "Busquedas"
                                    ? "btn btn-search active"
                                    : "btn btn-search"
                            }
                        ><i className="bi bi-search"></i></Link>
                        <Link
                            to="/auth"
                            alt="Iniciar sesión"
                            className={
                                props.active === "Iniciar sesión"
                                    ? "btn btn-inscribete active"
                                    : "btn btn-inscribete"
                            }
                        >Ingresar</Link>
                    </div>
                </div>
            </div>
        </header>
    )
};

export default Header;