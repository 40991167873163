import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";

import Moment from 'react-moment';
import 'moment/locale/es';

import Header from '../../components/header'
import Preview from '../../components/media/preview'

import GetApp from '../../components/home/getapp'
import Footer from '../../components/footer'

import Loading from '../../components/loading'

const Article = (props) => {
    const [data, setData] = useState([]);
    const [isLoading, setLoading] = useState(true);

    const { slug } = useParams();

    useEffect(() => {
        fetch(`https://noticias.lacajita.tv/wp-json/wp/v2/posts?_embed=author,wp:featuredmedia&slug=${slug}}`)
            .then((response) => response.json())
            .then((json) => setData(json))
            .catch((error) => console.error(error))
            .finally(() => setLoading(false));
    }, [slug]);


    if (isLoading) {
        document.title = `${data.name ?? 'Noticias'} | ${global.title}`
        return <Loading />
    }

    const { title, acf, date, content, cover, _embedded } = data[0];

    return (
        <>
            {/* Header */}
            <Header active="Noticias" />
            <article className="single">
                <div className="cover" style={{ backgroundImage: `url(${cover})` }}>
                    <Preview
                        title={
                            <Moment fromNow>{date}</Moment>
                        }
                        url={acf.mediaid}
                        data={data}
                        type="noticias"
                        fullscreen
                    />

                    <div className="container season">
                        <h2 className="h1 text-bold fw-bold news-text" style={{ marginBottom: 10 }}>{title.rendered}</h2>
                        <div className="text">
                            <Moment className="date" format='MMMM D YYYY, h:mm:ss a'>{date}</Moment>
                        </div>
                    </div>

                    <div className="container">
                        <div className="body" dangerouslySetInnerHTML={{ __html: content.rendered }} />
                    </div>

                    <div className="container">
                        <div className="author">
                            <span className="authorName">{_embedded.author[0].name}</span >
                            <span className="authorTitle">Autor</span>
                        </div>
                    </div>
                </div>
            </article>

            <GetApp />
            <Footer />
        </>
    )
}

export default Article;