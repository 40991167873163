import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom'
import Moment from 'react-moment';
import 'moment/locale/es';

import Header from '../../components/header'
import Trailer from '../../components/media/preview'

import Details from '../../components/media/item/details'
import GetApp from '../../components/home/getapp'
import Footer from '../../components/footer'

import url from "../../components/url";
import Loading from '../../components/loading';

const Item = (props) => {
    const { title } = props.location;
    const [active, setActive] = useState(0);
    const [isLoading, setLoading] = useState(true);
    const [data, setData] = useState({});
    const [episode, setEpisode] = useState({ id: '', name: '', link: '' });

    const { id, type } = useParams();
    const history = useHistory();

    useEffect(() => {
        if (id) {
            fetch(`${url}/${type}/${id}`)
                .then((response) => response.json())
                .then((json) => setData(json))
                .catch((error) => console.error(error))
                .finally(() => setLoading(false));
        } else {
            history.push("/");
        }
    }, [history, id, type])

    if (isLoading) {
        document.title = `${data.name ?? ''} | ${global.title}`
        return <Loading />
    }

    const toTop = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }
    return (
        <>
            {/* Header */}
            <Header active={title} />

            <section className="itemShow">
                <div className="cover" style={{ backgroundImage: `url(${url + data.cover.url})` }}>
                    <Trailer
                        theID={id}
                        id={episode.id === "" ? "x" : episode.id}
                        title={episode.name === "" ? "Trailer" : episode.name}
                        url={episode.link === "" ? data.trailer : episode.link}
                        type={type}
                        api={type}
                        playlist={episode.playlist}
                    />
                    <Details data={data} isLoading={isLoading} />

                    <div className="container season">

                        {type === "series"
                            ?
                            <div className="season__selector">
                                {data.season.map((e, i) =>
                                    <div key={e.id} className={active.active === e.id ? 'season_active' : 'season_inactive'}>
                                        <button
                                            className={active.active === e.id ? 'season_active_btn' : 'season_inactive_btn'}
                                            onClick={() => setActive({ active: e.id })}
                                        >Temporada {++i}</button>
                                    </div>
                                )}

                            </div>
                            : null
                        }

                        <div className="seasonDetails">
                            {data.season.filter(e => {
                                if (e.id === active.active) {
                                    return e.id === active.active
                                } else {
                                    return (e.id === data.season[data.season.length - 1].id)
                                }
                            }).map((e) =>
                                <div
                                    className="row"
                                    key={e.id}
                                >
                                    {e.Episodio.sort((b, a) => a.id - b.id).map((item) => {
                                        return (
                                            <div className="col-lg-3 col-md-6 col-6 mb-4" key={item.id}>
                                                <div className="card">
                                                    <div className="card__image" onClick={() => {
                                                        setEpisode(
                                                            {
                                                                id: item.id,
                                                                name: item.date,
                                                                link: item.url,
                                                                playlist: true
                                                            },
                                                            toTop()
                                                        )
                                                    }}>
                                                        <img src={`//content.jwplatform.com/thumbs/${item.url}-1920.jpg`} alt={item.name} />
                                                    </div>
                                                </div>
                                                <div className="card__info">
                                                    <span className="card__title" >{item.name}</span>
                                                    <span className="card__date" ><Moment format='MMMM D, YYYY'>{item.date}</Moment></span>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            )}
                        </div>
                    </div>

                </div>
            </section>

            <GetApp />
            <Footer />
        </>
    )
};

export default Item;