import React, { Fragment } from 'react';
import { Link } from 'react-router-dom'

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import url from "../url";
import options from "../owl"
import Loading from "../loading";
import Card from "../media/card";

const Item = (props) => {

    if (props.isLoading) return <Loading />

    const data = () => {
        if (props.type === 'tv-shows') {
            return props.content.tv_shows
        } else {
            return props.content.series
        }
    }
    return (
        <Fragment>
            <OwlCarousel {...options}>
                {data().slice(0, 21).map((item) => (
                    <Card
                        key={item.id}
                        title={item.name}
                        action={
                            {
                                pathname: `/${props.type}/${item.id}/${item.slug}`,
                                type: props.type,
                                title: `${props.type === 'tv-shows' ? 'TV Shows' : 'Series'}`,
                            }
                        }
                        alt={item.name}
                        image={url + item.cover.url}
                    />
                ))}

                <div className="card">
                    <Link
                        to={{
                            pathname: `/media/category/${props.title.toLowerCase().replace(' ', '-')}`,
                            type: props.type,
                            title: props.title,
                            isLoading: props.isLoading,
                            content: data()
                        }}
                        className="card__more"
                        alt="¡Descubre más!"
                    >
                        <span className="card__title">¡Descubre más! <i className="bi bi-arrow-right"></i></span>
                    </Link>
                </div>
            </OwlCarousel>
        </Fragment>
    )
};

export default Item;