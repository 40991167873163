import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'

import Loading from "../loading";
import Item from './item'

const Categories = () => {
    const [data, setData] = useState([]);
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        fetch('https://noticias.lacajita.tv/wp-json/wp/v2/categories?per_page=100')
            .then((response) => response.json())
            .then((json) => setData(json))
            .catch((error) => console.error(error))
            .finally(() => setLoading(false))
    }, []);

    if (isLoading) return <Loading />

    const Element = (item) => {
        if (item.count >= 1) {
            return (
                <div className="category news-page" key={item.id}>
                    <div className="d-flex justify-content-between align-items-center">
                        <h2 className="h2">{item.name}</h2>
                        <Link to="/noticias/category" className="more">Ver más <i className="bi bi-plus"></i></Link>
                    </div>

                    <div className="row">
                        <Item
                            id={item.id}
                            type={item.name}
                            content={data}
                        />
                    </div>

                </div>
            )
        }
    }

    return (
        <section className="container">
            {
                data
                    .sort(() => Math.random() - Math.random())
                    .slice(0, 10)
                    .map((item) => Element(item))
            }
        </section>
    );
}

export default Categories;