import React, { useState } from "react"
import { Link, useHistory } from 'react-router-dom'

import axios from "axios"

import Header from '../../components/header'
import GetApp from '../../components/home/getapp'
import Footer from '../../components/footer'
import Error from '../../components/error'

import url from "../../components/url"

const SignUp = ({ navigation }) => {
    const [name, setName] = useState()
    const [phone, setPhone] = useState()
    const [email, setEmail] = useState()
    const [password, setPassword] = useState()
    const [passwordT, setPasswordT] = useState()
    const [error, setError] = useState(false);

    const history = useHistory();

    const _handleSignUp = async () => {
        if (
            name !== undefined &&
            phone !== undefined &&
            !isNaN(phone) &&
            email !== undefined &&
            email.length > 5 &&
            password !== undefined &&
            password.length > 4 &&
            passwordT !== undefined &&
            passwordT.length > 4 &&
            passwordT === password
        ) {
            alert('Llegue')
            try {
                const { data } = await axios.get(`${url}/users`);
                const existingUser = data.filter(e => e.email === email);
                if (existingUser.length > 0) {
                    alert("Este usuario ya existe")
                } else {
                    await axios.post(
                        `${url}/auth/local/register`,
                        {
                            username: name,
                            email,
                            password,
                        }
                    )
                    try {
                        const { data } = await axios.post(`${url}/auth/local`, {
                            identifier: email,
                            password,
                        });
                        if (data.hasOwnProperty("jwt")) {
                            try {
                                localStorage.setItem("token", JSON.stringify({ token: data.jwt }));
                                localStorage.setItem("user", JSON.stringify({ user: data.user }));
                                history.push("/account");
                            } catch (error) {
                                console.log(error.message);
                            }

                        }
                    } catch (error) {
                        alert("Error | Intenta más tarde")
                    }
                }
            } catch (error) {
                setError(true)
            }
        } else {
            setError(true);
        }
    }

    return (
        <>
            {/* Header */}
            <Header active="Iniciar Sesión" />

            <div className="single auth login">
                <div className="container">
                    <h2 className="h1 text-bold fw-bold"><span className="h4">Crear</span><br /> Cuenta</h2>

                    <div className="row">
                        <div className="col-lg-6 col-md-6 mb-5">
                            {error ? <Error /> : null}

                            <input
                                type="text"
                                onChange={(text) => setName(text.target.value)}
                                placeholder="Nombre"
                                className={error ? "form-control normal normal-error" : "form-control normal"}
                            />
                            <input
                                type="phone"
                                onChange={(text) => setPhone(text.target.value)}
                                placeholder="Teléfono"
                                className={error ? "form-control normal normal-error" : "form-control normal"}
                            />
                            <input
                                type="email"
                                onChange={(text) => setEmail(text.target.value)}
                                placeholder="Correo electrónico"
                                className={error ? "form-control normal normal-error" : "form-control normal"}
                            />
                            <input
                                type="password"
                                placeholder="Contraseña"
                                onChange={(text) => setPassword(text.target.value)}
                                className={error ? "form-control normal normal-error" : "form-control normal"}
                            />
                            <input
                                type="password"
                                onChange={(text) => setPasswordT(text.target.value)}
                                placeholder="Repetir contraseña"
                                className={error ? "form-control normal normal-error" : "form-control normal"}
                            />

                            <div className="row">
                                <div className="col-12 mb-5">
                                    <button className="btn btn-active" onClick={_handleSignUp}>Crear cuenta&nbsp;<i className="bi bi-arrow-up-right"></i></button>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6">
                            <div className="login__account">
                                <Link to="/auth" className="btn">Iniciar sesión</Link>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            {/* Descargar Aplicacion */}
            <GetApp />

            {/* Footer */}
            <Footer />
        </>
    );
}
export default SignUp;