import React, { useState } from "react"
import { Link } from 'react-router-dom'

import axios from "axios"

import Header from '../../components/header'
import GetApp from '../../components/home/getapp'
import Footer from '../../components/footer'
import Error from '../../components/error'

import url from '../../components/url'

const Forgot = () => {
    const [email, setEmail] = useState();
    const [error, setError] = useState(false);


    const _handleForgot = async () => {
        if (email !== undefined) {
            const { data } = await axios.post(`${url}/auth/forgot-password`, { email });
            console.log(data);
            console.log("Revisa tu correo Electronico");
        } else {
            setError(true);
        }
    }

    return (
        <>
            {/* Header */}
            <Header active="Iniciar Sesión" />

            <div className="single auth login">
                <div className="container">

                    <div className="row">
                        <div className="col-lg-6">
                            <h2 className="h1 text-bold fw-bold"><span className="h4">Recuperar</span><br /> Cuenta</h2>

                            {error ? <Error /> : null}

                            <input
                                className={error ? "form-control normal normal-error" : "form-control normal"}
                                onChange={(text) => setEmail(text.target.value)}
                                placeholder="Correo electrónico"
                                autoFocus={true}
                                type="email"
                            />

                            <div className="row">
                                <div className="col-12 mb-5">
                                    <button className="btn btn-active" onClick={_handleForgot}>Recuperar&nbsp;<i className="bi bi-arrow-up-right"></i></button>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div className="login__account">
                                <Link to="/signup" className="btn">Crear cuenta</Link>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            {/* Descargar Aplicacion */}
            <GetApp />

            {/* Footer */}
            <Footer />
        </>
    );
}
export default Forgot