import MenuItem from "./menuItem";

function Navbar({ active }) {

    return (
        <nav className="navigation d-flex justify-content-center">
            <ul>
                <MenuItem
                    current={active}
                    dir="/"
                    name="Inicio"
                />
                <MenuItem
                    current={active}
                    dir="/tv-shows"
                    name="TV Shows"
                />
                <MenuItem
                    current={active}
                    dir="/live-tv"
                    name="Live TV"
                />
                <MenuItem
                    current={active}
                    dir="/noticias"
                    name="Noticias"
                />
                <MenuItem
                    current={active}
                    dir="/ondemand"
                    name="On Demand"
                />
            </ul>
        </nav>
    )
}
export default Navbar;