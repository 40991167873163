import React, { Fragment } from 'react';
import { Redirect } from 'react-router-dom'
import Header from '../../components/header'

import url from "../../components/url";
import Card from "../../components/media/card"

const AllShows = (props) => {
    const { title } = props.location;
    const { type } = props.location;
    const { content } = props.location;

    if (!content) {
        return <Redirect to='/' />
    }

    return (
        <Fragment>
            {/* Header */}
            <Header active={type === 'tv-shows' ? 'TV Shows' : 'Series'} />

            <div className="page">
                <article className="tvshows tvshows-page">
                    <div className="container">
                        <h2 className="display-4 text-bold fw-bold">{title}</h2>

                        <div className="row">
                            {content && content.map((item) => (
                                <div key={item.id} className="col-lg-3 col-md-6 col-6 mb-4">
                                    <Card
                                        key={item.id}
                                        title={item.name}
                                        action={
                                            {
                                                pathname: `/${type}/${item.id}/${item.slug}`,
                                                title: `${type === 'tv-shows' ? 'TV Shows' : 'Series'}`,
                                                type: type,
                                                id: item.id
                                            }
                                        }
                                        alt={item.name}
                                        image={url + item.cover.url}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </article>
            </div>
        </Fragment>
    )
};

export default AllShows;