import React, { useEffect, useState } from 'react';

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import url from "../url";
import options from "../owl"
import Loading from "../loading";
import Card from "../media/card";

const Recomend = (props) => {
    const [isLoading, setLoading] = useState(true);
    const [data, setData] = useState({});

    useEffect(() => {
        fetch(`${url}/${props.type}`)
            .then((response) => response.json())
            .then((json) => setData(json))
            .catch((error) => console.error(error))
            .finally(() => setLoading(false));
    }, [props])

    if (isLoading) return <Loading />

    return (
        <section className="tvshows tvshows-page">
            <div className="container">
                <h2 className={[props.type === "series" ? "display-4 text-bold fw-bold" : "h2 text-bold fw-bold"]}>{props.title}</h2>

                <OwlCarousel {...options}>
                    {data.slice(0, 17).map((item) => (
                        <Card
                            key={item.id}
                            title={item.name}
                            action={
                                {
                                    pathname: `/${props.type}/${item.id}/${item.slug}`,
                                    type: props.type,
                                    title: `${props.type === 'tv-shows' ? 'TV Shows' : 'On Demand'}`,
                                }
                            }
                            alt={item.name}
                            image={url + item.cover.url}
                        />
                    ))}
                </OwlCarousel>
            </div>
        </section>
    )
};

export default Recomend;