import React, { Fragment, useLayoutEffect, useEffect, useState, useRef } from 'react';
import { findDOMNode } from 'react-dom'
import ReactPlayer from 'react-player'
import screenfull from 'screenfull'


const Preview = (props) => {
    const [data, setData] = useState([]);
    const [duration, setDuration] = useState(0);
    const [isEnded, setEnded] = useState(false);
    const [isFullScreen, setFullScreen] = useState(false);
    const [isMuted, setMuted] = useState(true);
    const [isPlaying, setPlaying] = useState(true);
    const [over, setOver] = useState(false);
    const [played, setPlayed] = useState(0);
    const [progress, setProgress] = useState(0);
    const [seeking, setSeeking] = useState(false);
    const [pip, setPip] = useState(false);
    const [readyNext, setReadyNext] = useState(false);
    const [milliseconds, setMilliSeconds] = useState(0);
    const [nextEpisodeData, setNextEpisodeData] = useState({});
    const [isLoading, setLoading] = useState(true);
    const [counter, setCounter] = useState(milliseconds === 0 ? 10 : milliseconds - 10);

    const player = useRef(null);
    const videoRef = useRef(null);

    const { id, theID, title, type, url, scroll, api, playlist } = props;

    const [usePlayList, setPlayList] = useState(true)
    const [useUrl, setUseUrl] = useState(null)
    const [useTitle, setUseTitle] = useState(null)
    const [useId, setUseId] = useState(null)

    useEffect(() => {
        if (playlist === true) {
            if (api === "tv-shows" || api === "series") {
                fetch(`${global.fURL}/${api}`)
                    .then((res) => res.json())
                    .then((res) => setData(res))
                    .catch((error) => console.error(error))
                    .finally(() => setLoading(false));

                if (isLoading === false) {
                    const findID = data.filter(element => element.id === parseInt(theID));
                    const findSeason = findID[0].season[0].Episodio;
                    const findEpisode = findSeason.filter((e) => e.id < (usePlayList === true ? (useId === null ? id : useId) : id));

                    if (findEpisode.length > 0) {
                        if (player.current.getCurrentTime() >= player.current.getDuration() - 10) {
                            setReadyNext(true);
                            setPlayList(true);
                            setNextEpisodeData(findEpisode[findEpisode.length - 1])
                        } else {
                            setReadyNext(false);
                        }

                        if (player.current.getDuration() > 0 && player.current.getDuration() > 0) {
                            if (player.current.getDuration() === player.current.getCurrentTime()) {
                                if (nextEpisodeData) {
                                    setMilliSeconds(0);
                                    setReadyNext(false);
                                    setUseId(nextEpisodeData?.id)
                                    setUseUrl(nextEpisodeData?.url)
                                    setUseTitle(nextEpisodeData?.date)
                                    player.current.seekTo(parseFloat(0))
                                    setEnded(false)
                                    setPlaying(true)
                                }
                            }
                        }
                    }
                }
                if (readyNext) {
                    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
                }
            }
            return () => clearTimeout();
        }
    }, [api, counter, data, id, isLoading, nextEpisodeData, playlist, readyNext, theID, useId, usePlayList]);

    const daleNext = () => {
        setMilliSeconds(0);
        setReadyNext(false);
        setUseId(nextEpisodeData?.id)
        setUseUrl(nextEpisodeData?.url)
        setUseTitle(nextEpisodeData?.date)
        player.current.seekTo(parseFloat(0))
        setEnded(false)
        setPlaying(true)
    };

    const onPlay = () => {
        if (isEnded === true) {
            setEnded(false)
            setPlaying(true)
        } else {
            if (isPlaying === true) {
                setPlaying(false)
            } else {
                setPlaying(true)
            }
        }
    }

    const onMuted = () => {
        if (isMuted === true) {
            setMuted(false)
        } else {
            setMuted(true)
        }
    }

    const onFullScreen = () => {
        screenfull.request(findDOMNode(videoRef.current))
        setFullScreen(true);
    }
    const onFullScreenExit = () => {
        screenfull.exit(findDOMNode(videoRef.current))
        setFullScreen(false);
    }

    useLayoutEffect(() => {
        const timer = setTimeout(() => {
            if (over === true) {
                setOver(false);
            }
        }, 3000);

        return () => clearTimeout(timer);
    }, [over]);

    const overlay = () => setOver(true);

    const handleSeekMouseDown = () => {
        setSeeking(true)
    }

    const handleSeekChange = (e) => {
        setPlayed(parseFloat(e.target.value));
    }

    const handleSeekMouseUp = (e) => {
        setSeeking(false);
        player.current.seekTo(parseFloat(e.target.value))
    }

    const handleProgress = (state) => {
        if (!seeking) {
            setProgress(state.played)
            setPlayed(state.played)
        }
    }

    const handleDuration = (duration) => {
        setDuration(duration)
    }

    const format = (seconds) => {
        const date = new Date(seconds * 1000)
        const hh = date.getUTCHours()
        const mm = date.getUTCMinutes()
        const ss = pad(date.getUTCSeconds())
        if (hh) {
            return `${hh}:${pad(mm)}:${ss}`
        }
        return `${mm}:${ss}`
    }

    const pad = (string) => {
        return ('0' + string).slice(-2)
    }

    const Duration = ({ className, seconds }) => {
        return (
            <time dateTime={`P${Math.round(seconds)}S`} className={className}>
                {format(seconds)}
            </time>
        )
    }

    const handleEnded = () => {
        setEnded(true);
        setPlaying(false)
    }

    const seekBack = () => {
        player.current.seekTo(player.current.getCurrentTime() - 15)
    }

    const seekForward = () => {
        player.current.seekTo(player.current.getCurrentTime() + 15)
    }

    const handleEnablePIP = () => {
        setPip(true)
    }

    const handleDisablePIP = () => {
        setPip(false)
    }

    useEffect(() => {
        if (scroll >= 720) {
            handleEnablePIP()
        } else {
            handleDisablePIP()
        }
    })

    return (
        <Fragment>
            <div className="container mb-4" style={{ marginTop: 15 }}>
                <div className='player' ref={videoRef} onMouseMove={() => overlay()}>
                    <ReactPlayer
                        ref={player}
                        url={type === "tv" ? url : `https://cdn.jwplayer.com/manifests/${usePlayList === true ? (useUrl === null ? url : useUrl) : url}.m3u8`}
                        width='100%'
                        height='100%'
                        playing={isPlaying}
                        muted={isMuted}
                        pip={pip}
                        onEnded={handleEnded}
                        onProgress={handleProgress}
                        onDuration={handleDuration}

                        onEnablePIP={handleEnablePIP}
                        onDisablePIP={handleDisablePIP}
                    />

                    {readyNext ? (
                        <div className="viene" onClick={daleNext}>
                            <img className="viene__image" alt={nextEpisodeData?.name} src={`https://content.jwplatform.com/thumbs/${nextEpisodeData?.url}-1920.jpg`} />
                            <div className="viene__container">
                                <span className="viene__text">{nextEpisodeData?.name}</span>
                            </div>
                            <div className="viene__counter">
                                <span className="viene__counter_text">{counter}</span>
                            </div>
                        </div>
                    ) : null}

                    <div className="control" style={over ? { display: 'flex' } : { display: 'none' }}>
                        <h2 className="h2 text-bold fw-bold title">{usePlayList === true ? (useTitle === null ? title : useTitle) : title}</h2>

                        {isFullScreen
                            ? <button onClick={() => onFullScreenExit()} className="btn x"><i className="bi bi-x"></i></button>
                            : null
                        }

                        <div className="controlBnts">
                            {type === "tv"
                                ? null
                                :
                                <>
                                    {!isEnded
                                        ?
                                        <button onClick={() => seekBack()} className="play">
                                            <i className="bi bi-arrow-counterclockwise"></i>
                                        </button>
                                        : null
                                    }
                                </>
                            }

                            <button onClick={() => onPlay()} className="play">
                                {isEnded
                                    ? <i className="bi bi-arrow-repeat"></i>
                                    :
                                    <>
                                        {isPlaying
                                            ? <i className="bi bi-pause"></i>
                                            : <i className="bi bi-play"></i>
                                        }
                                    </>
                                }
                            </button>

                            {type === "tv"
                                ? null
                                :
                                <>
                                    {!isEnded
                                        ?
                                        <button onClick={() => seekForward()} className="play">
                                            <i className="bi bi-arrow-clockwise"></i>
                                        </button>
                                        : null
                                    }
                                </>
                            }
                        </div>

                        <div className="superControl">
                            <div className="range">
                                {type === "tv"
                                    ? null
                                    :
                                    <>
                                        <span className="text-time">
                                            <Duration seconds={duration} />
                                        </span>
                                        <span className="text-time">/</span>
                                        <span className="text-time">
                                            <Duration seconds={duration * (1 - progress)} />
                                        </span>
                                    </>
                                }
                                {type === "tv"
                                    ? null
                                    :
                                    <input
                                        type='range' min={0} max={0.999999} step='any'
                                        value={played}
                                        onMouseDown={handleSeekMouseDown}
                                        onChange={handleSeekChange}
                                        onMouseUp={handleSeekMouseUp}
                                        className="track"
                                    />
                                }

                            </div>
                            <div className="options">

                                {isPlaying && type === "tv"
                                    ? <span className="text-live">En Vivo</span>
                                    : null
                                }
                                <button onClick={() => onMuted()} className="btn">
                                    {isMuted
                                        ? <i className="bi bi-volume-mute"></i>
                                        : <i className="bi bi-volume-up"></i>
                                    }
                                </button>
                                {isFullScreen
                                    ?
                                    <button onClick={() => onFullScreenExit()} className="btn">
                                        <i className="bi bi-fullscreen-exit"></i>
                                    </button>
                                    :
                                    <button onClick={() => onFullScreen()} className="btn">
                                        <i className="bi bi-fullscreen"></i>
                                    </button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default Preview;