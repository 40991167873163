import { Fragment } from 'react';
import { Link } from 'react-router-dom';


function NavButon(props) {
    return (
        <Fragment>
            <li className={props.current === props.name ? "active" : null}><Link to={props.dir}>{props.name}</Link></li>
        </Fragment>
    );
}

export default NavButon;