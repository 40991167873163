import React, { useState, useRef } from "react"
import { Link, useHistory } from 'react-router-dom'

import axios from "axios"

import Loading from "../../components/loading"
import Header from '../../components/header'
import GetApp from '../../components/home/getapp'
import Footer from '../../components/footer'
import Error from '../../components/error'

import url from '../../components/url'

const Login = () => {
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const passwordRef = useRef();
    const history = useHistory();


    const _handleLogin = async () => {
        if (email !== undefined && email.length > 5 && password !== undefined && password.length > 4) {
            setLoading(true);
            try {
                const { data } = await axios.post(`${url}/auth/local`, {
                    identifier: email,
                    password,
                });
                if (data.hasOwnProperty("jwt")) {
                    try {
                        localStorage.setItem("token", JSON.stringify({ token: data.jwt }));
                        localStorage.setItem("user", JSON.stringify({ user: data.user }));
                        history.push("/account");
                    } catch (error) {
                        console.log(error.message);
                    }

                }
                setLoading(false);
            } catch (error) {
                setLoading(false);
                setError(true);
            }
        }
    }

    if (loading) return <Loading />;

    return (
        <>
            {/* Header */}
            <Header active="Iniciar Sesión" />

            <div className="single auth login">
                <div className="container">


                    <div className="row">
                        <div className="col-lg-6 col-md-6 mb-5">
                            <h2 className="h1 text-bold fw-bold"><span className="h4">Iniciar</span><br /> Sesión</h2>

                            {error ? <Error /> : null}

                            <input
                                className={error ? "form-control normal normal-error" : "form-control normal"}
                                onChange={(text) => setEmail(text.target.value)}
                                placeholder="Correo electrónico"
                                autoFocus={true}
                                type="email"
                            />
                            <input
                                className={error ? "form-control normal normal-error" : "form-control normal"}
                                onChange={(text) => setPassword(text.target.value)}
                                placeholder="Contraseña"
                                ref={passwordRef}
                                type="password"
                            />


                            <div className="row">
                                <div className="col-12 mb-5">
                                    <button className="btn btn-active" onClick={_handleLogin}>Iniciar sesión&nbsp;<i className="bi bi-arrow-up-right"></i></button>
                                </div>
                                <div className="col">
                                    <Link to="/forgot" className="btn-forgot text-center w-100">¿Olvidaste tu contraseña?</Link>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6">
                            <div className="login__account">
                                <Link to="/signup" className="btn">Crear cuenta</Link>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            {/* Descargar Aplicacion */}
            <GetApp />

            {/* Footer */}
            <Footer />
        </>
    );
}
export default Login