import React from 'react'
import { Link } from 'react-router-dom'

import Moment from 'react-moment';
import 'moment/locale/es';

export default function card(props) {

    const {
        action,
        alt,
        children,
        date,
        image,
        title,
    } = props;

    return (
        <Link to={action} className="card">
            <div className="card__image">
                <img src={`${image}`} alt={alt} />
            </div>
            <div className="card__info">
                <span className="card__title">{title}</span>
                {date
                    ?
                    <span className="card__date">
                        <Moment fromNow>{date}</Moment>
                    </span>
                    : null
                }
            </div>
            {children}
        </Link>
    )
}
