import React from 'react';
import Navigation from './navigation'

const Footer = () => {

    return (
        <footer className="footer">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-4 col-12">
                        <span className="copyright">Todos los derechos son reservados.<br /> Emfravision Media, LLC.  2021</span>
                    </div>
                    <div className="col-lg-8 col-12">
                        <div className="navigation">
                            <Navigation />
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
};

export default Footer;