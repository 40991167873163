import React, { useState, useEffect } from "react"
import { useHistory } from 'react-router-dom'

import Loading from "../components/loading"
import Header from '../components/header'
import GetApp from '../components/home/getapp'
import Footer from '../components/footer'

const Account = () => {
    const [user, setUser] = useState({});
    const [id, setID] = useState(0);
    const [loading, setLoading] = useState(false);

    const history = useHistory();

    useEffect(() => {
        setLoading(true);
        const user = localStorage.getItem("user");

        setUser(JSON.parse(user).user);
        setID(JSON.parse(id));
        setLoading(false);
    }, [id]);


    const LogOut = async () => {
        try {
            localStorage.removeItem("token");
            localStorage.removeItem("user");
            history.push("/");
        } catch (error) {
            console.log(error.message)
        }
    };


    if (loading) return <Loading />

    return (
        <>
            {/* Header */}
            <Header active="Iniciar Sesión" />

            <div className="single auth account">
                <div className="container">


                    <div className="row">
                        <div className="col-lg-6 col-md-6 mb-5">
                            <h2 className="h1 text-bold fw-bold"><span className="h4">Usuario</span><br /> {user?.username ?? "User"}</h2>

                            <div className="dato texto">
                                <div className="device">
                                    <div className="deviceCircl">
                                        <span className="deviceCounte">1</span>
                                    </div>
                                    <span className="deviceTitl">Dispositivos</span>
                                </div>

                                <div className="dat">
                                    <span className="dateTex"> 04/03/2021 </span>
                                    <span className="dateTitl">Fecha de experación</span>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6">
                            <div className="login__account">
                                <button onClick={() => LogOut()} className="btn">Cerrar cuenta</button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            {/* Descargar Aplicacion */}
            <GetApp />

            {/* Footer */}
            <Footer />
        </>
    );
}
export default Account