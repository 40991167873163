import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import Moment from 'react-moment';
import 'moment/locale/es';

import Header from '../../components/header'

import GetApp from '../../components/home/getapp'
import Footer from '../../components/footer'

import Loading from '../../components/loading'
import url from '../../components/url'

const Pages = (props) => {
    const [isLoading, setLoading] = useState(true);
    const [data, setData] = useState([]);

    const { id } = useParams();

    useEffect(() => {
        fetch(`${url}/paginas/${id}`)
            .then((response) => response.json())
            .then((json) => setData(json))
            .catch((error) => console.error(error))
            .finally(() => setLoading(false));
    }, [id]);

    const { name, description, updated_at } = data;


    if (isLoading) return <Loading />

    return (
        <>
            {/* Header */}
            <Header />
            <article className="single" style={{ marginTop: 50 }}>
                <div className="container season">
                    <h2 className="single__title single__title_news">{name}</h2>

                    <div className="text">
                        <Moment className="date" format='MMMM D YYYY, h:mm:ss a'>{updated_at}</Moment>
                    </div>
                </div>

                <div className="container">
                    <div className="body" dangerouslySetInnerHTML={{ __html: description }} />
                </div>

            </article>

            <GetApp />
            <Footer />
        </>
    )
}

export default Pages;