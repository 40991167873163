import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import url from "../url";

const Navigation = () => {
    const [isLoading, setLoading] = useState(true);
    const [data, setData] = useState({});

    useEffect(() => {
        fetch(`${url}/paginas?_sort=name:asc`)
            .then((response) => response.json())
            .then((json) => setData(json))
            .catch((error) => console.error(error))
            .finally(() => setLoading(false));
    }, []);

    if (isLoading) return <></>

    return (
        <ul>
            {data.map((e) => (
                <li key={e.id} className="item"><Link to={`/${e.id}/${e.slug}`}>{e.name}</Link></li>
            ))}
        </ul>
    )
};

export default Navigation;