import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import url from "../url";
import options from "../owl"
import Loading from "../loading";
import Card from "../media/card";

const Shows = (props) => {
    const [isLoading, setLoading] = useState(true);
    const [data, setData] = useState({});

    useEffect(() => {
        fetch(`${url}/opciones`)
            .then((response) => response.json())
            .then((json) => setData(json))
            .catch((error) => console.error(error))
            .finally(() => setLoading(!data));
    }, [])

    if (isLoading) return <Loading />

    return (
        <section className="tvshows tvshows-page">
            <div className="container">
                <h2 className="display-4 text-bold fw-bold">{props.title}</h2>

                <OwlCarousel {...options}>
                    {data.hasOwnProperty("TOP") && data.TOP[1].tv_shows.map((item) => (
                        <Card
                            key={item.id}
                            title={item.name}
                            action={
                                {
                                    pathname: `/tv-shows/${item.id}/${item.slug}`,
                                    title: 'TV Shows',
                                    type: 'tvshow',
                                    id: item.id
                                }
                            }
                            alt={item.name}
                            image={url + item.cover.url}
                        />
                    ))}
                    {props.more === true
                        ?
                        <div className="card">
                            <Link to="/tv-shows" className="card__more" alt="¡Descubre más!">
                                <span className="card__title">¡Descubre más! <i className="bi bi-arrow-right"></i></span>
                            </Link>
                        </div>
                        : null
                    }
                </OwlCarousel>
            </div>
        </section >
    )
};

export default Shows;