import React, { useEffect } from 'react';
import Header from '../components/header'
import Shows from '../components/home/shows'
import Recomend from '../components/media/recomend'
import Category from '../components/media/category'
import GetApp from '../components/home/getapp'
import Footer from '../components/footer'

const TvShows = () => {
    const name = "TV Shows";

    useEffect(() => {
        document.title = `${name} | ${global.title}`;

    }, [])
    return (
        <>
            {/* Header */}
            <Header active={name} />

            <main className="page">
                {/* Principales shows */}
                <Shows
                    title="TV Shows"
                    more={false}
                />

                {/* Shows Recomendados */}
                <Recomend
                    title="Recientes"
                    item={4}
                    type="tv-shows"
                />

                {/* Categorias */}
                <Category
                    title={name}
                    item={4}
                    type="tv-shows"
                />
            </main>

            {/* Descargar Aplicacion */}
            <GetApp />

            {/* Footer */}
            <Footer />
        </>
    )
};

export default TvShows;