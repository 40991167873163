import React, { useEffect } from 'react';
import Header from '../components/header'
import Slider from '../components/slider'
import Shows from '../components/home/shows'
import Tv from '../components/home/livetv'
import News from '../components/home/news'
import Series from '../components/home/series'
import GetApp from '../components/home/getapp'
import Footer from '../components/footer'

const Home = () => {
    const name = "Inicio";

    useEffect(() => {
        document.title = `${global.title}`
    }, [])

    return (
        <>
            {/* Header */}
            <Header active={name} />

            {/* Slider */}
            <Slider />

            {/* Principales shows */}
            <Shows
                title="TV Shows"
                more={true}
            />

            {/* Live TV */}
            <Tv />

            {/* Ultimas Noticias */}
            <News more={true} />

            {/* Series */}
            <Series more={true} />

            {/* Get the App */}
            <GetApp />

            {/* Footer */}
            <Footer />
        </>
    )
};

export default Home;