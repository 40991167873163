import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import ScrollToTop from "./components/scrollToTop"
import Home from "./pages/home"

// TV SHOWS
import TvShows from "./pages/tvshows"

// LIVE TV
import LiveTV from "./pages/livetv"

// NOTICIAS
import News from "./pages/news"
import Article from "./pages/media/article"

// SERIES
import Series from "./pages/series"

// 404
import NotFount from "./pages/404"

// MEDIA
import AllMedia from "./pages/media/all"
import ItemMedia from "./pages/media/item"

import Search from "./pages/search"
import Page from "./pages/media/page"

//Auth
import Login from "./pages/auth/login"
import SignUp from "./pages/auth/signup"
import Forgot from './pages/auth/forgot'
import Account from './pages/account'


function App() {
    global.title = "La Cajita";
    global.fURL = "//cajita.concepcion.tech";
    return (
        <Router>
            <ScrollToTop />
            <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/inicio" component={Home} />
                <Route exact path="/index" component={Home} />

                <Route exact path="/tv-shows" component={TvShows} />
                <Route exact path="/media/category/:category" component={AllMedia} />
                <Route exact path="/:type/:id/:item" component={ItemMedia} />

                <Route exact path="/live-tv" component={LiveTV} />

                <Route exact path="/noticias" component={News} />
                <Route exact path="/noticias/:slug" component={Article} />

                <Route exact path="/ondemand" component={Series} />
                <Route exact path="/media/category/:category" component={AllMedia} />
                <Route exact path="/:type/:id/:item" component={ItemMedia} />

                <Route exact path="/search" component={Search} />
                <Route exact path="/:id/:slug" component={Page} />

                <Route exact path="/auth" component={Login} />
                <Route exact path="/signup" component={SignUp} />
                <Route exact path="/forgot" component={Forgot} />
                <Route exact path="/account" component={Account} />

                <Route path="*">
                    <NotFount />
                </Route>
            </Switch>
        </Router>
    );
}

export default App;
