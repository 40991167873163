import React from 'react'
import { Link } from 'react-router-dom'

import Moment from 'moment';
import 'moment/locale/es'

import Card from '../media/card'
import Channel from '../tv/channel'

const Result = ({ datos, type, navigation }) => {
    if (type === "tv") {
        return (
            <div className="w-100">
                <div className="row content">
                    {
                        datos.length > 0 ? datos.map(item =>
                            <Link
                                alt={item.name}
                                key={item.id}
                                to={{
                                    pathname: `/live-tv`,
                                    title: item.name,
                                    link: item.link,
                                    id: item.id
                                }}

                                className="col-lg-4 col-md-6 col-12 mb-4"
                                style={{ textDecoration: 'none' }}
                            >
                                <Channel
                                    id={item.id}
                                    logo={item.logo.url}
                                    number={item.number}
                                    name={item.name}
                                    country={item.country.country}
                                    action={() => null}
                                />
                            </Link>
                        ) : <SearchIcon />
                    }
                </div>
            </div >
        )
    }

    if (type === "tv-shows") {
        return (
            <div className="w-100 tvshows m-0">
                <div className="row">
                    {datos.length > 0 ? datos.map(item =>
                        <div className="col-lg-3 col-md-6 col-6 mb-4" key={item.id}>
                            <Card
                                title={item.name}
                                image={global.fURL + item.cover.url}
                                alt={item.name}
                                action={
                                    {
                                        pathname: `/tv-shows/${item.id}/${item.slug}`,
                                        title: 'TV Shows',
                                        type: 'tv-shows',
                                        id: item.id
                                    }
                                }
                            >
                                <div className={{ marginBottom: 15 }} />
                            </Card>
                        </div>
                    ) : <SearchIcon />}
                </div>
            </div>
        )
    }

    if (type === "noticias") {
        return (
            <div className="w-100 tvshows m-0">
                <div className="row">
                    {datos.length > 0 ? datos.map(e =>
                        <div className="col-lg-3 col-md-6 col-6 mb-4" key={e.id}>
                            <Link className="card"
                                to={{
                                    pathname: `/noticias/${e.slug}`,
                                    title: 'Noticias',
                                    type: 'noticias',
                                    id: e.id
                                }}
                                alt={e.title.rendered}>
                                <div className="card__image">
                                    <img src={`${e._embedded['wp:featuredmedia'][0].media_details.sizes.medium_large.source_url}`} alt="" className="itemImage" />
                                </div>
                                <div className="card__info">
                                    <span className="card__title">{e.title.rendered}</span>
                                    <span className="card__date">{Moment(e.date).fromNow()}</span>
                                </div>
                            </Link>
                        </div>
                    ) : <SearchIcon />}
                </div>
            </div >
        )
    }

    if (type === "series") {
        return (
            <div className="w-100 tvshows m-0">
                <div className="row">
                    {datos.length > 0 ? datos.map(item =>
                        <div className="col-lg-3 col-md-6 col-6 mb-4" key={item.id}>
                            <Card
                                title={item.name}
                                image={global.fURL + item.cover.url}
                                alt={item.name}
                                action={
                                    {
                                        pathname: `/series/${item.id}/${item.slug}`,
                                        title: 'Series',
                                        type: 'series',
                                        id: item.id
                                    }
                                }
                            >
                                <div className={{ marginBottom: 15 }} />
                            </Card>
                        </div>
                    ) : <SearchIcon />}
                </div>
            </div>
        )
    }

    return (
        <>
            <span className="title">Null</span>
            <SearchIcon />
        </>
    )
}
export default Result

const SearchIcon = () => {
    return (
        <div className="search__icon">
            <img src='/images/search.png' alt="Sin resultados" />
        </div>
    )
}