import React, { useEffect } from 'react';
import Header from '../components/header'
import GetApp from '../components/home/getapp'
import Footer from '../components/footer'

const Home = () => {
    useEffect(() => {
        document.title = `404 - página no encontrada | ${global.title}`
    }, [])
    return (
        <>
            {/* Header */}
            <Header />

            <div className="container">
                <div className="error404">
                    <h1 className="error404__title">404</h1>
                    <span>página no encontrada</span>
                </div>
            </div>

            {/* Descargar Aplicacion */}
            <GetApp />

            {/* Footer */}
            <Footer />
        </>
    )
};

export default Home;