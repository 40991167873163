import React, { useEffect } from 'react';

import Header from '../components/header'
import News from '../components/home/news'
import Category from '../components/news/category'
import GetApp from '../components/home/getapp'
import Footer from '../components/footer'

const Noticias = () => {
    const name = "Noticias";

    useEffect(() => {
        document.title = `${name} | ${global.title}`
    }, [])

    return (
        <>
            {/* Header */}
            <Header active={name} />

            <main className="page">
                {/* Ultimas Noticias */}
                <News more={false} margin />

                {/* Categorias Noticias */}
                <Category />
            </main>

            {/* Descargar Aplicacion */}
            <GetApp />

            {/* Footer */}
            <Footer />
        </>
    )
};

export default Noticias;