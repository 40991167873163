import React from 'react'

const Error = () => {
    return (
        <div className="error">
            <div className="errorText">
                <span className="errorTitle">Error</span>
                <span className="errorDescription">Datos incompletos o incorrectos</span>
            </div>
        </div>
    )
}

export default Error;