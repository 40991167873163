const options = {
    nav: false,
    dots: false,
    lazyLoad: true,
    items: 4,
    margin: 20,
    navText: ['<i className="bi bi-arrow-left"></i>', '<i className="bi bi-arrow-right"></i>'],
    className: "owl-carousel owl-slider",
    responsive: {
        0: {
            items: 2,
        },
        600: {
            items: 2,
        },
        1022: {
            items: 3,
        },
        1140: {
            items: 4,
            nav: true,
        },
    },
}

export default options;