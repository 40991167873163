import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'

import url from "../url";
import Loading from "../loading";

const Tv = () => {
    const [isLoading, setLoading] = useState(true);
    const [data, setData] = useState({});

    useEffect(() => {
        fetch(`${url}/opciones`)
            .then((response) => response.json())
            .then((json) => setData(json))
            .catch((error) => console.error(error))
            .finally(() => setLoading(false));
    }, [])

    if (isLoading) return <Loading />

    return (
        <section className="livetv">
            <div className="container">
                <div className="row nogutters">
                    <div className="col-lg-12 panel">
                        <h2 className="display-4 text-bold fw-bold">Live TV</h2>

                        <div className="channels">
                            {data.hasOwnProperty("TOP") && data.TOP[0].live_tvs.slice(0, 10).map((item) => (
                                <div key={item.id} className="item">
                                    <Link
                                        to={{
                                            pathname: `/live-tv`,
                                            title: item.name,
                                            link: item.link,
                                            id: item.id
                                        }}
                                        alt={item.name}
                                    >
                                        <img src={`${url + item.logo.url}`} alt={item.name} />
                                        <span>{item.name}</span>
                                    </Link>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
};

export default Tv;