import React, { useEffect, useState } from 'react';
// import { Link } from 'react-router-dom'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import url from "../url";

const Slider = () => {
    const [isLoading, setLoading] = useState(true);
    const [data, setData] = useState({});

    useEffect(() => {
        fetch(`${url}/slider`)
            .then((response) => response.json())
            .then((json) => setData(json))
            .catch((error) => console.error(error))
            .finally(() => setLoading(false));
    }, [])

    if (isLoading) {
        return (
            <div className="slider">
                <div className="loading"><div></div><div></div></div>
            </div>
        )
    }
    return (
        <div className="slider">
            <OwlCarousel
                items={1}
                loop
                dots
                autoplay
                lazyLoad
                autoplayHoverPause
                autoplayTimeout={10000}
                className="owl-carousel owl-slider"
            >
                {data.Slider.map((item) => (
                    <div className="item" key={item.Imagen.id}>
                        <img src={`${url + item.Imagen.url}`} alt="hol" />
                    </div>
                ))}
            </OwlCarousel>
        </div>
    )
};

export default Slider;