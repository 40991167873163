import React, { useEffect } from 'react';
import Header from '../components/header'
import Recomend from '../components/media/recomend'
import Category from '../components/media/category'
import GetApp from '../components/home/getapp'
import Footer from '../components/footer'

const Series = () => {
    const name = "On Demand";

    useEffect(() => {
        document.title = `${name} | ${global.title}`
    }, [])

    return (
        <>
            {/* Header */}
            <Header active={name} />

            <main className="page">
                {/* Shows Recomendados */}
                <Recomend
                    item={4}
                    title="On Demand"
                    type="series"
                />


                {/* Categorias */}
                <Category
                    title={name}
                    item={4}
                    type="series"
                />
            </main>

            {/* Descargar Aplicacion */}
            <GetApp />

            {/* Footer */}
            <Footer />
        </>
    )
};

export default Series;