import React, { Fragment, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import Moment from 'react-moment';
import 'moment/locale/es';

import Loading from "../loading";

const Item = (props) => {
    const [data, setData] = useState([]);
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        fetch(`https://noticias.lacajita.tv/wp-json/wp/v2/posts?categories=${props.id}&per_page=3&_embed=author,wp:featuredmedia`)
            .then((response) => response.json())
            .then((json) => setData(json))
            .catch((error) => console.error(error))
            .finally(() => setLoading(false))
    }, [props.id]);

    if (isLoading) return <Loading />

    const Element = (e) => {
        return (
            <div key={e.id} className="col-lg-4 col-md-6 mb-4">
                <Link className="item"
                    to={{
                        pathname: `/noticias/${e.slug}`,
                        title: 'Noticias',
                        type: 'noticias',
                        id: e.id
                    }}
                >
                    <div className="image">
                        <img src={`${e._embedded['wp:featuredmedia'][0].media_details.sizes.medium_large.source_url}`} alt={e.title.rendered} />
                    </div>
                    <div>
                        <span className="title">{e.title.rendered}</span>
                        <span className="date"><Moment fromNow>{e.date}</Moment></span>
                    </div>
                </Link>
            </div>
        )
    }
    return (
        <Fragment>
            {
                data.map((e) => Element(e))
            }
        </Fragment>
    )
}

export default Item;