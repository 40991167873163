import React from 'react';
import { Link } from 'react-router-dom'

const GetApp = () => {
    return (
        <section className="getapp">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-3 col-md-4 col-12">
                        <div className="muckup"></div>
                    </div>
                    <div className="col-lg-9 col-md-8">
                        <h2 className="getapp__title">Fácil de usar & <br /> en tu plataforma <br />favorita</h2>

                        <div className="platform">
                            <Link to="#" target="_blank" className="item">
                                <img src="/images/googleplay.svg" alt="Google Play Store" />
                            </Link>
                            <Link to="#" target="_blank" className="item">
                                <img src="/images/applestore.svg" alt="Apple Store" />
                            </Link>
                            <Link to="#" target="_blank" className="item">
                                <img src="/images/roku.svg" alt="Roku" />
                            </Link>
                            <Link to="#" target="_blank" className="item">
                                <img src="/images/amazonappstore.svg" alt="Amazon App Store" />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
};

export default GetApp;