import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Loading from "../loading";
import Card from "../media/card";

const News = (props) => {
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState({});

  useEffect(() => {
    (async () => {
      try {
        let response = await fetch(
          `//noticias.lacajita.tv/wp-json/wp/v2/posts?_embed=author,wp:featuredmedia&per_page=8`
        );
        let json = await response.json();
        setData(json);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(!data);
      }
    })();

    //    fetch(`http://noticias.lacajita.tv/wp-json/wp/v2/posts?_embed=author,wp:featuredmedia&per_page=8`)
    //         .then((response) => response.json())
    //         .then((json) => setData(json))
    //         .catch((error) => console.error(error))
    //         .finally(() => setLoading(false));
  }, []);

  if (isLoading) return <Loading />;

  return (
    <section className="news news-page">
      <div className="container">
        <h2 className="display-4 text-bold fw-bold">Noticias</h2>

        <div className="row">
          {data.lenght > 0 && data.map((item) => (
            <div className="col-lg-3 col-md-6 col-6" key={item.id}>
              <Card
                title={item.title.rendered}
                date={item.date}
                action={{
                  pathname: `/noticias/${item.slug}`,
                  title: "Noticias",
                  type: "noticias",
                  id: item.id,
                }}
                alt={item.name}
                image={
                  item._embedded["wp:featuredmedia"][0].media_details.sizes
                    .medium_large.source_url
                }
              />
            </div>
          ))}
        </div>

        {props.more === true ? (
          <Link to="/noticias" className="btn btn-more" alt="¡Descubre más!">
            ¡Descubre más!
          </Link>
        ) : null}
      </div>
    </section>
  );
};

export default News;
