import React, { useEffect, useState } from 'react';

import url from "../url";
import Item from './item';
import Loading from "../loading";

const Category = (props) => {
    const [isLoading, setLoading] = useState(true);
    const [data, setData] = useState({});

    useEffect(() => {
        fetch(`${url}/categorias`)
            .then((response) => response.json())
            .then((json) => setData(json))
            .catch((error) => console.error(error))
            .finally(() => setLoading(false));
    }, [])

    if (isLoading) return <Loading />

    const Elements = (item) => {
        const tipo = () => {
            if (props.type === 'tv-shows') {
                return (item?.tv_shows)
            } else {
                return item?.series
            }
        }
        if (tipo() > []) {
            return (
                <div className="tvshows tvshows-page" key={item.id}>
                    <h2 className="h2 text-bold fw-bold">{item.name}</h2>
                    <Item
                        id={item.id}
                        title={item.name}
                        sLoading={isLoading}
                        category={item.name}
                        content={item}
                        item={props.item}
                        type={props.type}
                    />
                </div>
            )
        }
    }

    return (
        <section>
            <div className="container">
                {
                    data
                        .sort(() => Math.random() - Math.random())
                        .map((item) => Elements(item))
                }
            </div>
        </section>
    )
};

export default Category;