import React, { useState, useEffect } from 'react';

import Header from '../components/header'
import Preview from '../components/media/preview'
import GetApp from '../components/home/getapp'
import Footer from '../components/footer'

import url from "../components/url";
import Loading from "../components/loading";
import Channel from "../components/tv/channel"

const LiveTV = (props) => {
    const { id } = props.location;
    const { title } = props.location;
    const { link } = props.location;

    const [data, setData] = useState([]);
    const [channel, setChannel] = useState({
        id: `${id ?? 20}`,
        name: `${title ?? 'Emfravisión'}`,
        link: `${link ?? 'https://cdn.essastream.com:3447/live/emfravisionlive.m3u8'}`
    });
    const [categories, setCategories] = useState([]);
    const [config, setConfig] = useState([]);
    const [countries, setCountries] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [languages, setLanguages] = useState([]);
    const [scrollY, setScrollY] = useState(0);
    const [type, setType] = useState({ btn: 0, thetitle: 'Null', type: 'todo' });

    const flags = "//flag.concepcion.tech/";
    const name = "Live TV";

    useEffect(() => {
        fetch(`${url}/live-tvs?_sort=number:asc`)
            .then((response) => response.json())
            .then((json) => setData(json))
            .catch((error) => console.error(error))
            .finally(() => setLoading(false));

        fetch(`${url}/categorias?_sort=name:asc`)
            .then((response) => response.json())
            .then((json) => setCategories(json))
            .catch((error) => console.error(error))
            .finally(() => setLoading(false));

        fetch(`${url}/paises?_sort=country:asc`)
            .then((response) => response.json())
            .then((json) => setCountries(json))
            .catch((error) => console.error(error))
            .finally(() => setLoading(false));

        fetch(`${url}/idiomas?_sort=language:asc`)
            .then((response) => response.json())
            .then((json) => setLanguages(json))
            .catch((error) => console.error(error))
            .finally(() => setLoading(false));

        fetch(`${url}/opciones`)
            .then((response) => response.json())
            .then((json) => setConfig(json))
            .catch((error) => console.error(error))
            .finally(() => setLoading(false));
    }, [])

    useEffect(() => {
        document.title = `${channel.name} | ${global.title}`
    }, [channel.name])


    const logit = () => {
        setScrollY(window.pageYOffset);
    }

    useEffect(() => {
        const watchScroll = () => {
            window.addEventListener("scroll", logit);
        }
        watchScroll();

        return () => {
            window.removeEventListener("scroll", logit);
        };
    }, []);


    if (isLoading) return <Loading />

    const categoryList = (e) => {
        if (e?.live_tvs > []) {
            return (
                <span
                    key={e.id}
                    style={{ cursor: 'pointer' }}
                    onClick={() => setType({ btn: 2, thetitle: e.name, type: e.name })}
                    className="item"
                >{e.name}</span>
            )
        }
    }

    const countryList = (e) => {
        if (data.find((p => p?.country?.id === e.id))) {
            return (
                <span
                    key={e.id}
                    style={{ cursor: 'pointer' }}
                    onClick={() => setType({ btn: 3, thetitle: e.country, type: e.country })}
                    className="item">
                    <img className="flag" src={`${flags + e.alias}.png`} alt={e.country} /> {e.country}
                </span>

            )
        }
    }

    const languagesList = (e) => {
        if (data.find((l => l?.country?.id === e.id))) {
            return (
                <span
                    key={e.id}
                    style={{ cursor: 'pointer' }}
                    onClick={() => setType({ btn: 6, thetitle: e.language, type: e.language })}
                    className="item">
                    <img className="flag" src={`${flags + e.alias}.png`} alt={e.language} /> {e.language}
                </span>
            )
        }
    }

    const channelList = (item) => {
        if (item.language !== null && item.categorias.name !== null) {
            console.log(item.categorias.name);
            if (
                type.type === 'todo' ||
                item.categorias.find((element => element.name === type.type)) ||
                item.country.country === type.type ||
                item.language.language === type.type
            ) {
                return (
                    <div className="col-lg-4 col-md-6 col-12 mb-4" key={item.id}>
                        <Channel
                            id={item.id}
                            action={() => { setChannel({ id: item.id, name: item.name, link: item.link }, toTop()) }}
                            channel={channel.id}
                            logo={item.logo.url}
                            number={item.number}
                            name={item.name}
                            country={item.country.country}
                            countries={countries}
                            config={config}
                        />
                    </div >
                )
            }
        }
    }

    const toTop = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }

    return (
        <>
            {/* Header */}
            <Header active={name} />

            {/* Preview */}
            <Preview
                type="tv"
                unique={channel.id}
                title={channel.name}
                url={channel.link}
                scroll={scrollY}
            />

            <div className="container" style={{ marginTop: 30 }}>
                <div className="filter">
                    <div className="select">
                        <div className="item">
                            <button className={type.btn === 0 ? 'active' : 'inactive'} onClick={() => setType({ btn: 0, thetitle: 'Todos los canales', type: 'todo' })}>
                                <i className="bi bi-graph-up"></i>&nbsp;Top
                            </button>
                        </div>
                        <div className="item">
                            <button className={type.btn === 1 ? 'active' : 'inactive'} onClick={() => setType({ btn: 1, thetitle: 'Todos los canales', type: 'todo' })}>Todos</button>
                        </div>
                        <div className="item">
                            <button className={type.btn === 2 ? 'active' : 'inactive'}>
                                Categoría&nbsp;<i className="bi bi-chevron-down"></i>
                                <div className="list">{categories.map((e) => categoryList(e))}</div>
                            </button>
                        </div>
                        <div className="item">
                            <button className={type.btn === 3 ? 'active' : 'inactive'}>
                                País&nbsp;<i className="bi bi-chevron-down"></i>
                                <div className="list" style={{ width: 230 }}>{countries.map((e) => countryList(e))}</div>
                            </button>
                        </div>
                        <div className="item">
                            <button className={type.btn === 4 ? 'active' : 'inactive'} onClick={() => setType({ btn: 4, thetitle: 'Noticias', type: 'Noticias' })}>Noticias</button>
                        </div>
                        <div className="item">
                            <button className={type.btn === 5 ? 'active' : 'inactive'} onClick={() => setType({ btn: 5, thetitle: 'Deportes', type: 'Deportes' })}>Deportes</button>
                        </div>
                        <div className="item">
                            <button className={type.btn === 6 ? 'active' : 'inactive'}>
                                Idioma&nbsp;<i className="bi bi-chevron-down"></i>
                                <div className="list">{languages.map((e, i) => languagesList(e))}</div>
                            </button>
                        </div>
                    </div>

                    <div className="content">
                        {type.btn === 1 || type.btn === 2 || type.btn === 3 || type.btn === 4 || type.btn === 5 || type.btn === 6
                            ? <div>
                                <div style={{ marginTop: 30, marginBottom: 20 }}>
                                    <h2 className="h2 text-bold fw-bold">{type.thetitle}</h2>
                                </div>

                                <div className="row">
                                    {data.map((item) => channelList(item))}
                                </div>
                            </div>
                            :
                            <div>
                                <div style={{ marginTop: 30, marginBottom: 20 }}>
                                    <h2 className="h2 text-bold fw-bold">Top 10: Canales</h2>
                                </div>

                                <div className="row">
                                    {config.hasOwnProperty("TOP") && config.TOP[0].live_tvs.slice(0, 10).map((item) =>
                                        <div className="col-lg-4 col-md-6 col-12 mb-4" key={item.id}>
                                            <Channel
                                                id={item.id}
                                                action={() => { setChannel({ id: item.id, name: item.name, link: item.link }, toTop()) }}
                                                channel={channel.id}
                                                logo={item.logo.url}
                                                number={item.number}
                                                name={item.name}
                                                country={item.country}
                                                countries={countries}
                                                config={config}
                                                type="top"
                                            />
                                        </div>
                                    )}
                                </div>
                                <button className="btn btn-more" style={{ marginTop: 50 }} onClick={() => setType({ btn: 1, thetitle: 'Todos los canales', type: 'todo' })}>
                                    <span>Ver todos los canales <i className="bi bi-arrow-right"></i></span>
                                </button>
                            </div>

                        }
                    </div>
                </div>
            </div>

            {/* DESCARGAR APLICACION */}
            <GetApp />

            {/* FOOTER */}
            <Footer />
        </>
    )
};

export default LiveTV;
