import React, { Fragment } from 'react';
import Moment from 'react-moment';
import 'moment/locale/es';

const Seasons = (props) => {
    if (props.isLoading) {
        return (
            <div className="slider">
                <div className="loading"><div></div><div></div></div>
            </div>
        )
    }

    return (
        <Fragment>
            <div className="container season">
                <h2 className="h1 text-bold fw-bold" style={{ marginBottom: 10 }}>{props.data.name}</h2>
                <span className="details">
                    <Moment format='Y'>{props.year}</Moment>&nbsp;&nbsp;|&nbsp;&nbsp;
                    {props.data.season.length} {props.data.season.length <= 1 ? "Temporada" : "Temporadas"}&nbsp;&nbsp;|&nbsp;&nbsp;
                    {props.data.language?.language ?? 'Lenguage desconocido'}
                </span>

                <div className="details">
                    <p>{props.data.description}</p>
                </div>
            </div>
        </Fragment>
    )
};

export default Seasons;