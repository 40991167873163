import React from 'react'

import url from "../url";

const Channel = (props) => {
    const {
        id,
        channel,
        action,
        logo,
        number,
        name,
        country,
        countries,
        config,
        type
    } = props

    const countriesList = (e) => {
        if (config.hasOwnProperty("TOP") && config.TOP[0].live_tvs.find((p => country === e.id))) {
            return (
                <span key={e.id} className="country">{e.country}</span>
            )
        }
    }
    return (
        <div onClick={() => action()} className={channel === id ? ' channel selected' : 'channel'}>
            <div className="logo">
                <img className="img" src={url + logo} alt="" />
                <div className="number">
                    <span className="numberT">{number}</span>
                </div>
            </div>
            <div className="info">
                <span className="title">{name}</span>
                {type === 'top' ? countries.map((e) => countriesList(e)) : <span className="country">{country}</span>}
            </div>

        </div>

    )
}

export default Channel;