import React, { useState, useEffect } from 'react'

import Result from "../components/search/result";
import Header from '../components/header'
import GetApp from '../components/home/getapp'
import Footer from '../components/footer'

const Search = (props) => {
    const [renderData, setRenderData] = useState([]);
    const [active, setActive] = useState('tv');
    const [data, setData] = useState([]);

    const name = "Busquedas";

    useEffect(() => {
        document.title = `${name} | ${global.title}`;

    }, [])

    const _handleState = (type) => {
        setActive(type);
        setData([]);
        setRenderData([]);
    }

    const _handleFetch = async (value, n = null) => {
        if (n === null) {
            try {
                const data = await fetch(`${global.fURL}/${value}`);
                const response = await data.json();
                setData(response);
            } catch (error) {
                console.log(error);
            }
        } else {
            try {
                const data = await fetch(n);
                const response = await data.json();
                setData(response);
            } catch (error) {
                console.log(error);
            }
        }
    }

    const _handleFind = (text, n) => {
        switch (active) {
            case "tv":
                if (data.length <= 0) {
                    _handleFetch("live-tvs");
                }
                if (data[0] !== undefined) {
                    const searching = data.filter(e => e.name.toLowerCase().includes(text.toLowerCase()));
                    if (searching[0] !== undefined) {
                        setRenderData(searching)
                    }
                }
                break;
            case "tv-shows":
                if (data.length <= 0) {
                    _handleFetch("tv-shows");
                }
                if (data[0] !== undefined) {
                    const searching = data.filter(e => e.name.toLowerCase().includes(text.toLowerCase()));
                    if (searching[0] !== undefined) {
                        setRenderData(searching)
                    }
                }
                break;
            case "noticias":
                if (data.length <= 0) {
                    _handleFetch("noticias", n = "https://noticias.lacajita.tv/wp-json/wp/v2/posts?_embed=author,wp:featuredmedia&per_page=100");
                }
                if (data[0] !== undefined) {
                    const searching = data.filter(e => e.title.rendered.toLowerCase().includes(text.toLowerCase()));
                    if (searching[0] !== undefined) {
                        setRenderData(searching)
                    }
                }
                break
            case "series":
                if (data.length <= 0) {
                    _handleFetch("series")
                }
                if (data[0] !== undefined) {
                    const searching = data.filter(e => e.name.toLowerCase().includes(text.toLowerCase()));
                    if (searching[0] !== undefined) {
                        setRenderData(searching)
                    }
                }
                break;
            default:
        }
    }

    const btn = (value) => active === value ? 'btn btn-active' : 'btn'

    return (
        <>
            {/* Header */}
            <Header active={name} />

            <section className="search" style={props.margin ? { marginBottom: 30 } : null}>
                <div className="container">
                    <input
                        className="input"
                        autoFocus={true}
                        onChange={text => _handleFind(text.target.value)}
                        placeholder='Buscar contenido'
                        type="search"
                    />
                    <div className="filter">
                        <button className={btn('tv')} onClick={_ => _handleState("tv")}>Live TV</button>
                        <button className={btn('tv-shows')} onClick={_ => _handleState("tv-shows")}>TV Shows</button>
                        <button className={btn('noticias')} onClick={_ => _handleState("noticias")}>Noticias</button>
                        <button className={btn('series')} onClick={_ => _handleState("series")}>Series</button>
                    </div>

                    <Result datos={renderData} type={active} />
                </div>
            </section>

            {/* Descargar Aplicacion */}
            <GetApp />

            {/* Footer */}
            <Footer />
        </>
    )
}
export default Search;